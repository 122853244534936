<template>
  <Container>
    <Header>
      Selling Sheet | {{ popOrder.formatCount }} <strong> {{ popOrder.buyerName }} </strong>
      {{ popOrder.shippingText ? " | " + popOrder.shippingText : "" }}
    </Header>
    <PageContent>
      <SellingTable :popOrder="popOrder" />
      <template #right>
        <BuyerRecyme :buyerId="popOrder.buyerId" />
      </template>
    </PageContent>
  </Container>
  <SellingModal v-if="modal" :popOrder="popOrder" />
</template>

<script>
import comp from "../base"
import SellingTable from "./SellingSheetTable.vue"
import BuyerRecyme from "../recymes/BuyerRecyme.vue"
import { format } from "date-fns"
import { computed } from "vue"
import { useRoute } from "vue-router"
import SellingModal from "./SellingModal.vue"
import { useStore } from "@/store/store"

export default {
  name: "TapSheet",
  components: {
    ...comp,
    BuyerRecyme,
    SellingTable,
    SellingModal,
  },
  setup() {
    return {
      format,
      popOrder: computed(() => useStore().getters.popOrder(useRoute().params.id)),
      modal: computed(() => useStore().state.localModals.selling),
    }
  },
}
</script>
