
import { mapActions } from "vuex"
import comp from "../base"
import { BUYER } from "../../services/enums"
import { format } from "date-fns"
import { useStore } from "@/store/store"
import { computed, defineComponent } from "@vue/runtime-core"

export default defineComponent({
  name: "BuyerResyme",
  props: {
    buyerId: {
      type: String,
      required: true,
    },
  },
  components: {
    ...comp,
  },
  setup(props) {
    return {
      BUYER,
      format,
      buyer: computed(() => useStore().getters.buyer(props.buyerId)),
    }
  },
  methods: {
    ...mapActions(["openBuyer"]),
  },
})
