<script lang="ts">
import { mapActions } from "vuex"
import comp from "../base"
import { BUYER } from "../../services/enums"
import { format } from "date-fns"
import { useStore } from "@/store/store"
import { computed, defineComponent } from "@vue/runtime-core"

export default defineComponent({
  name: "BuyerResyme",
  props: {
    buyerId: {
      type: String,
      required: true,
    },
  },
  components: {
    ...comp,
  },
  setup(props) {
    return {
      BUYER,
      format,
      buyer: computed(() => useStore().getters.buyer(props.buyerId)),
    }
  },
  methods: {
    ...mapActions(["openBuyer"]),
  },
})
</script>

<template>
  <SubHeader>
    Buyer Recyme
    <template #right>
      <BadgeBtn class="mb-1" @click="openBuyer(buyerId)"> edit buyer</BadgeBtn>
    </template>
  </SubHeader>
  <div class="boxCardSide p-3">
    <List>
      Name :
      <template #right> {{ buyer.name }} </template>
    </List>
    <List>
      Status :
      <template #right> {{ BUYER.TEXT[buyer.state] }} </template>
    </List>
    <hr />
    <List>
      Phone :
      <template #right> {{ buyer.phone }} </template>
    </List>
    <List>
      Address :
      <template #right> {{ buyer.address1 }} </template>
    </List>
    <List>
      City :
      <template #right> {{ buyer.city }} </template>
    </List>
    <List>
      Zip :
      <template #right> {{ buyer.zip }} </template>
    </List>
    <List>
      Country :
      <template #right> {{ buyer.country }} </template>
    </List>
    <hr />
    <List>
      Created :
      <template #right> {{ buyer.createdText }} </template>
    </List>
    <List v-if="buyer.updated">
      Updated :
      <template #right> {{ format(buyer.updated.toDate(), "dd/MM/yyyy") }} </template>
    </List>
  </div>
</template>
