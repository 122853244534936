<template>
  <td>
    <ButtonGroup>
      <Button @click="del()"> trash</Button>
    </ButtonGroup>
  </td>
  <td v-for="(callback, index) in dataCallbacl" :key="index">{{ callback(item) }}</td>
  <td>
    <FormInputEnableable
      ref="formQuantity"
      type="number"
      :validateion="validNum"
      v-model.number="quantity"
      @lock="setQuantity()"
      :span="item.unitText"
    />
  </td>
</template>

<script>
import comp from "../base"
import { apiSetOrder } from "../../services/api"
import { checkValidity, formError, validNum } from "../../services/validation"
import { useStore } from "@/store/store"
import { cloneDeep } from "lodash"

export default {
  name: "SellingTableItem",
  props: ["item"],
  components: {
    ...comp,
  },
  data() {
    return {
      dataCallbacl: [
        item => item.brewName,
        item => item.containerName,
        item => item.containerSize,
        item => item.quantity * item.containerSize + item.containerUnitText,
      ],
      orderId: this.$route.params.id,
      quantity: null,
    }
  },
  setup() {
    return {
      validNum,
    }
  },
  created() {
    this.setData()
  },
  watch: {
    item() {
      this.setData()
    },
  },

  methods: {
    setData() {
      if (this.item) {
        this.quantity = this.item.quantity
      }
    },

    del() {
      let local = cloneDeep(useStore().state.collections.orders[this.orderId])
      let index = local.inventory
        .map(item => item.containerId + item.brewId)
        .indexOf(this.item.containerId + this.item.brewId)
      local.inventory.splice(index, 1)
      apiSetOrder(local)
    },

    setQuantity() {
      if (checkValidity(this.$refs.formQuantity)) {
        let local = cloneDeep(useStore().state.collections.orders[this.orderId])
        let index = local.inventory
          .map(item => item.containerId + item.brewId)
          .indexOf(this.item.containerId + this.item.brewId)
        local.inventory[index].quantity = this.quantity
        apiSetOrder(local)
      } else formError()
    },
  },
}
</script>
