<template>
  <SubHeader>
    Overview
    <template #right><BadgeBtn @click="openLocalSelling()"> + </BadgeBtn></template>
  </SubHeader>
  <CardBox>
    <BTable
      :header="['', 'Brew', 'Type', 'Size', 'Total Volume', 'Quantity']"
      :footer="['sum', , , , , ,]"
    >
      <tr v-for="(item, index) in popOrder.inventory" :key="index">
        <SellingTableItem :item="item" />
      </tr>
    </BTable>
  </CardBox>
</template>

<script>
import { mapActions } from "vuex"
import comp from "../base"
import SellingTableItem from "./SellingSheetTableItem.vue"
export default {
  name: "TapOvervirew",
  props: ["popOrder"],
  components: {
    ...comp,
    SellingTableItem,
  },
  methods: {
    ...mapActions(["openLocalSelling"]),
  },
}
</script>
