<template>
  <ModalBase :close="closeLocal">
    <template #header>Add item to order</template>
    <input class="form-control" placeholder="search (TODO)" />
    <BTable :header="['add', 'brew', 'name', 'size', 'items']">
      <template v-for="(brew, brewIndex) in brewsDone" :key="brewIndex">
        <template v-for="(container, containerIndex) in brew.containers" :key="containerIndex">
          <tr v-if="!added.includes(brew.id + container.id)">
            <td><Button @click="add(brew.id, container.id)"> add</Button></td>
            <td>{{ brew.recipeName }}</td>
            <td>{{ container.name }}</td>
            <td>{{ container.size }}{{ container.unitText }}</td>
            <td>{{ container.brewTotal }}</td>
          </tr>
        </template>
      </template>
    </BTable>
  </ModalBase>
</template>

<script>
import comp from "../base"
import cloneDeep from "clone-deep"
import { mapActions } from "vuex"
import ModalBase from "../modals/ModalBase.vue"
import { apiSetOrder } from "../../services/api"
import { BREW } from "../../services/enums"
import { useStore } from "@/store/store"

export default {
  name: "SellingModal",
  props: ["popOrder"],
  components: {
    ...comp,
    ModalBase,
  },
  computed: {
    added() {
      return this.popOrder.inventory.map(item => item.brewId + item.containerId)
    },
    brewsDone() {
      return useStore().getters.popBrews.filter(item => item.state == BREW.DONE)
    },
  },

  methods: {
    ...mapActions(["closeLocal"]),
    add(brewId, containerId) {
      let order = cloneDeep(useStore().state.collections.orders[this.popOrder.id])
      order.inventory.push({
        containerId: containerId,
        brewId: brewId,
      })
      apiSetOrder(order)
      this.closeLocal()
    },
  },
}
</script>
